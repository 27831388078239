import React from "react"
import { css } from "../../../../styled-system/css";
import "../../../styles/keyframes.css";

import agenda from "../../../images/index/Icons/agenda.svg";
import casque from "../../../images/index/Icons/casque.svg";
import montre from "../../../images/index/Icons/montre.svg";
import smartphone from "../../../images/index/Icons/smartphone.svg";
import photo from "../../../images/index/Icons/photo.svg";
import softbox from "../../../images/index/Icons/softbox.svg";
import objectif from "../../../images/index/Icons/objectif.svg";
import trepied from "../../../images/index/Icons/trepied.svg";

const L = [
    { svg: agenda, scale: 1 },
    { svg: casque, scale: 1 },
    { svg: montre, scale: 1.1 },
    { svg: smartphone, scale: 1 },
];

const R = [
    { svg: photo, scale: 1 },
    { svg: softbox, scale: 1.2 },
    { svg: objectif, scale: 1.25 },
    { svg: trepied, scale: 1.2 },
];

const FloatingObjects = () => {
    return (
        <div className={css({
            position: "absolute",
            top: "0",
            left: "0",
            w: "100%",
            h: "100%",
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden",
            zIndex: 0,
        })}>
            <div className={css({
                flex: "0.33",
                w: "35%",
                h: "100%",
                position: "relative",
            })}>
                {L.map((src, i) => (
                    <div className={css({
                        position: "absolute",
                        top: i % 2 == 0 ? "50%" : "35%",
                        w: "7.5rem",
                        h: "7.5rem",
                    })} style={{
                        left: `calc(25% * ${i + .5})`,
                        transform: "translate(-50%, -50%) scale(" + src.scale + ")",
                    }} key={i}>
                        <img className={css({
                            position: "absolute",
                            w: "100%",
                            h: "100%",
                            top: "0",
                            left: "0",
                            filter: "drop-shadow(0 0 1rem white)",
                            animation: "float 5s infinite",
                        })} style={{
                            animationDelay: parseFloat((Math.random()).toString()).toFixed(2) + "s"
                        }} src={src.svg} alt="" />
                    </div>
                ))}
            </div>
            <div className={css({
                flex: "0.33",
                w: "35%",
                h: "100%",
                position: "relative",
            })}>
                {R.map((src, i) => (
                    <div className={css({
                        position: "absolute",
                        top: i % 2 == 0 ? "50%" : "35%",
                        w: "7.5rem",
                        h: "7.5rem",
                    })} style={{
                        left: `calc(25% * ${i + .5})`,
                        transform: "translate(-50%, -50%) scale(" + src.scale + ")",
                    }} key={i}>
                        <img className={css({
                            position: "absolute",
                            w: "100%",
                            h: "100%",
                            top: "0",
                            left: "0",
                            filter: "drop-shadow(0 0 1rem white)",
                            animation: "float 5s infinite",
                        })} style={{
                            animationDelay: parseFloat((Math.random()).toString()).toFixed(2) + "s"
                        }} src={src.svg} alt="" />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FloatingObjects;