import { Link } from 'gatsby'
import React, { useState } from 'react'
import { css } from '../../../../styled-system/css'
import '../../../styles/keyframes.css'


import Rocket_B from '../../../images/index/Rocket_B.svg'

import Rocket_G from '../../../images/index/Rocket_G.svg'

const FooterMenus = () => {
  const [Box1, setBox1] = useState(false)
  const [Box2, setBox2] = useState(false)
  let timeout: NodeJS.Timeout | null = null

  const handleEnterDrop = (DropID: number) => {
    if (timeout) {
      clearTimeout(timeout)
      timeout = null
    }

    if (DropID === 1) {
      setBox1(true)
      setBox2(false)
    } else {
      setBox1(false)
      setBox2(true)
    }
  }

  const handleLeaveDrop = () => {
    if (timeout) clearTimeout(timeout)

    timeout = setTimeout(() => {
      setBox1(false)
      setBox2(false)
    }, 500)
  }

  return (
    <div
      className={css({
        zIndex: 1,
        w: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
        alignItems: 'center',
      })}
    >
      <div
        className={css({
          position: 'absolute',
          bottom: '1rem',
          left: '50%',
          transform: 'translateX(-50%)',
        })}
      >
        <h1
          className={css({
            fontFamily: "'Exo 2' !",
            fontSize: '2rem',
            textAlign: 'center',
            color: '#fff',
            position: 'relative',
            w: '100%',
            _before: {
              content: "''",
              display: 'block',
              position: 'absolute',
              top: '50%',
              left: '-4rem',
              width: '2.5rem',
              height: '.2rem',
              backgroundColor: '#fff',
            },
            _after: {
              content: "''",
              display: 'block',
              position: 'absolute',
              top: '50%',
              right: '-4rem',
              width: '2.5rem',
              height: '.2rem',
              backgroundColor: '#fff',
            },
            xlDown: {
              fontSize: '1.5rem',
              _before: {
                width: '1.5rem',
                left: '-2.25rem',
              },
              _after: {
                width: '1.5rem',
                right: '-2.25rem',
              },
            },
          })}
        >
          Choisissez votre univers
        </h1>
      </div>
      <div
        className={css({
          w: '35%',
          h: '27rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: 'column',
          gap: '1.5rem',
          background: Box1 ? 'rgba(255,255,255, 0.3)' : 'transparent',
          borderTop: Box1 ? '.125rem solid #006BFF' : 'none',
          borderRight: Box1 ? '.125rem solid #006BFF' : 'none',
          transform: !Box1 ? 'translateY(21.5rem)' : 'none',
          transition: 'all .5s',
          padding: '1rem',
          xlDown: {
            gap: '1.25rem',
            padding: '0.75rem',
            transform: !Box1 ? 'translateY(22.5rem)' : 'none',
          },

          '@media screen and (min-width: 1270px) and (max-width: 1610px)': {
            w: '45%',
          },
        })}
        onMouseLeave={handleLeaveDrop}
        onMouseEnter={() => handleEnterDrop(1)}
      >
        <h2
          className={css({
            fontFamily: "'Exo 2' !",
            fontSize: '1.5rem',
            fontWeight: '600',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#006BFF',
            letterSpacing: '.1rem',
            marginTop: '1rem',
            xlDown: {
              fontSize: '1.25rem',
              marginTop: '0.75rem',
            },
          })}
        >
          Expertise en recrutement
        </h2>
        <div
          className={css({
            fontFamily: 'Lato !',
            fontSize: '1rem',
            fontWeight: '300',
            color: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '1rem',
            xlDown: {
              fontSize: '0.9rem',
              gap: '0.5rem',
            },
          })}
        >
          <p>
            Découvrez notre service de recrutement spécialisé dans l&apos;IT et le
            Web.
          </p>
          <p>
            En tant que chasseur de tête, nous excellons dans le sourcing et le
            recrutement de talents d&apos;exception. Notre expertise en recrutement
            vous assure de trouver les professionnels les mieux adaptés à vos
            besoins.
          </p>
          <p>
            Faites confiance à notre équipe pour un recrutement de talent
            efficace et une croissance optimale de votre entreprise.
          </p>
          <p>
            Contactez-nous dès aujourd&apos;hui pour des solutions sur mesure et un
            accompagnement personnalisé.
          </p>
        </div>
        <Link
          to={'/recrutement/'}
          className={css({
            marginBottom: '.75rem',
            cursor: 'pointer',
            xlDown: {
              marginBottom: '.5rem',
            },
          })}
        >
          <div
            className={css({
              position: 'relative',
              padding: '.4rem',
              cursor: 'pointer',
              overflow: 'hidden',
              '& span': {
                opacity: '1',
                transition: 'all .3s',
              },
              '&:not(:hover) span': {
                opacity: '0',
              },
              '& span:nth-child(1)': {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '2px',
                background: 'linear-gradient( to right, transparent, #006BFF )',
                animation: 'border-animetion1 2s linear infinite 1s',
              },
              '& span:nth-child(2)': {
                position: 'absolute',
                top: '0',
                right: '0',
                width: '2px',
                height: '100%',
                background:
                  'linear-gradient( to bottom, transparent, #006BFF )',
                animation: 'border-animetion2 2s linear infinite',
              },
              '& span:nth-child(3)': {
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                height: '2px',
                background: 'linear-gradient( to left, transparent, #006BFF )',
                animation: 'border-animetion3 2s linear infinite 1s',
              },
              '& span:nth-child(4)': {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '2px',
                height: '100%',
                background: 'linear-gradient( to top, transparent, #006BFF )',
                animation: 'border-animetion4 2s linear infinite',
              },
            })}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <button
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                color: '#006BFF',
                textTransform: 'uppercase',
                fontFamily: "'Exo 2' !",
                fontSize: '1.25rem',
                fontWeight: '600',
                letterSpacing: '.1rem',
                gap: '.5rem',
                padding: '.5rem 1rem',
                border: '.125rem solid #006BFF',
                position: 'relative',
              })}
            >
              J&apos;y vais
              <img src={Rocket_B} alt="" />
            </button>
          </div>
        </Link>
      </div>
      <div
        className={css({
          w: '35%',
          h: '27rem',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          flexDirection: 'column',
          gap: '1.5rem',
          background: Box2 ? 'rgba(255,255,255, 0.3)' : 'transparent',
          borderTop: Box2 ? '.125rem solid #D9C58B' : 'none',
          borderLeft: Box2 ? '.125rem solid #D9C58B' : 'none',
          transform: !Box2 ? 'translateY(21.5rem)' : 'none',
          transition: 'all .5s',
          padding: '1rem',
          xlDown: {
            gap: '1.25rem',
            padding: '0.75rem',
            transform: !Box2 ? 'translateY(22.5rem)' : 'none',
          },
          '@media screen and (min-width: 1270px) and (max-width: 1610px)': {
            w: '45%',
          },
        })}
        onMouseLeave={handleLeaveDrop}
        onMouseEnter={() => handleEnterDrop(2)}
      >
        <h2
          className={css({
            fontFamily: "'Exo 2' !",
            fontSize: '1.5rem',
            fontWeight: '600',
            textAlign: 'center',
            textTransform: 'uppercase',
            color: '#D9C58B',
            letterSpacing: '.1rem',
            marginTop: '1rem',
            xlDown: {
              fontSize: '1.25rem',
              marginTop: '0.75rem',
            },
          })}
        >
          Photographie
        </h2>
        <div
          className={css({
            fontFamily: 'Lato !',
            fontSize: '1rem',
            fontWeight: '300',
            color: '#FFF',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: '1rem',
            xlDown: {
              fontSize: '0.8rem',
              gap: '0.5rem',
            },
          })}
        >
          <p>
            Découvrez notre service de photographe à Lille spécialisé en studio
            et lifestyle.
          </p>
          <p>
            En tant qu&apos;experts en shooting photo, nous excellons dans la capture
            de moments précieux de votre vie. Notre expertise en séances photo
            vous assure des souvenirs authentiques et élégants, que ce soit pour
            une grossesse, une naissance, un baptême, un mariage, ou des
            portraits de famille. Faites confiance à notre équipe pour des
            séances photo uniques et une expérience inoubliable.
          </p>
          <p>
            Contactez-nous dès aujourd&apos;hui pour des solutions sur mesure et un
            accompagnement personnalisé dans notre studio photo à Chéreng près
            de Lille.
          </p>
        </div>
        <Link
          to={'/photographie/'}
          className={css({
            marginBottom: '.75rem',
            cursor: 'pointer',
            xlDown: {
              marginBottom: '.5rem',
            },
          })}
        >
          <div
            className={css({
              position: 'relative',
              padding: '.4rem',
              overflow: 'hidden',
              cursor: 'pointer',
              '& span': {
                opacity: '1',
                transition: 'all .3s',
              },
              '&:not(:hover) span': {
                opacity: '0',
              },
              '& span:nth-child(1)': {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '2px',
                background: 'linear-gradient( to right, transparent, #D9C58B )',
                animation: 'border-animetion1 2s linear infinite 1s',
              },
              '& span:nth-child(2)': {
                position: 'absolute',
                top: '0',
                right: '0',
                width: '2px',
                height: '100%',
                background:
                  'linear-gradient( to bottom, transparent, #D9C58B )',
                animation: 'border-animetion2 2s linear infinite',
              },
              '& span:nth-child(3)': {
                position: 'absolute',
                bottom: '0',
                left: '0',
                width: '100%',
                height: '2px',
                background: 'linear-gradient( to left, transparent, #D9C58B )',
                animation: 'border-animetion3 2s linear infinite 1s',
              },
              '& span:nth-child(4)': {
                position: 'absolute',
                top: '0',
                left: '0',
                width: '2px',
                height: '100%',
                background: 'linear-gradient( to top, transparent, #D9C58B )',
                animation: 'border-animetion4 2s linear infinite',
              },
            })}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <button
              className={css({
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: '#D9C58B',
                textTransform: 'uppercase',
                fontFamily: "'Exo 2' !",
                fontSize: '1.25rem',
                fontWeight: '600',
                letterSpacing: '.1rem',
                gap: '.5rem',
                padding: '.5rem 1rem',
                border: '.125rem solid #D9C58B',
                position: 'relative',
                cursor: 'pointer',
              })}
            >
              J&apos;y vais
              <img src={Rocket_G} alt="" />
            </button>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default FooterMenus
